import { useEffect, useRef, useState, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  TableContainer,
  TableContent,
  TableHeader,
  TABLE_LAYOUT_OPTIONS,
  usePreventTableScrollingRef,
  IconDelete,
  IconButton,
  Dialog,
  BUTTON_VARIANTS,
  withTooltip,
  TOOLTIP_HORIZONTAL_ALIGNMENTS,
  TOOLTIP_VERTICAL_ALIGNMENTS,
  THEMES,
  IndeterminateProgressCircle,
  EmptyStatesIllustration,
} from "cdk-radial";
import classNames from "classnames";
import "./styles.css";
import axios from "axios";
import config from "../config/app.conf.json";

const CustomNoRowsOverlay = ({
  message = "This org has no contracted Bundles",
}) => (
  <div className="empty_no_rows_overlay">
    <EmptyStatesIllustration size={180} />
    <div className="empty_state_illustration_text">{message}</div>
  </div>
);

const EllipsisCellRenderer = ({ value }) => (
  <div className="ellipsis_cell_renderer">{value || ""}</div>
);
export function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { month: "short", day: "numeric", year: "numeric" };
  let formattedDate = date.toLocaleDateString("en-US", options);

  // Add the ordinal suffix to the day
  const day = date.getDate();
  const suffixes = ["th", "st", "nd", "rd"];
  const suffix =
    suffixes[
      day % 10 < 4 && day % 10 > 0 && (day < 11 || day > 13) ? day % 10 : 0
    ];
  formattedDate = formattedDate.replace(/(\d+)/, "$1" + suffix);
  return formattedDate;
}

const DateCellRenderer = ({ value }) => (
  <div className="ellipsis_cell_renderer">{formatDate(value)}</div>
);

export default function ContractedBundlesGrid({
  contractedBundles = [],
  auth,
  setIsLoading,
  setShowToast,
  setToastMessage,
  setToastType,
  selectedFields,
  selectedBundles,
}) {
  const [data, setData] = useState([]);
  const [contractedBundlesCount, setContractedBundlesCount] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [elementToBeDeleted, setElementToBeDeleted] = useState([]);
  const { accessToken } = auth;
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const gridRef = useRef();
  const tableContentRef = usePreventTableScrollingRef();

  const DeleteContractActionButtonRenderer = ({ data }) => {
    const isDisabled = data?.fullBundle?.apis?.some(
      (api) => api.hasIntegration
    );

    const removeBundleContractbuttonTooltipProps = {
      id: "remove_bundle_contract_tooltip-id",
      text: "Cannot delete this bundle as API(s) in this bundle are in use.",
      theme: THEMES.LIGHT,
    };
    const HelpRenderTooltipButtonOne = (props) => {
      return (
        <IconButton
          icon={<IconDelete />}
          isDisabled={isDisabled}
          text="Button"
          {...props}
        />
      );
    };
    const IconWithTooltip = withTooltip(
      HelpRenderTooltipButtonOne,
      removeBundleContractbuttonTooltipProps
    );
    if (isDisabled) {
      return (
        <IconWithTooltip
          horizontalAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.LEFT}
          marginAroundElement={8}
          verticalAlignment={TOOLTIP_VERTICAL_ALIGNMENTS.TOP}
        />
      );
    } else {
      return (
        <IconButton
          icon={<IconDelete />}
          text="Button"
          onClick={() => {
            setDeleteDialogOpen(true);
            setDeleteDialogOpen(true);
            setElementToBeDeleted(data?.fullBundle);
          }}
        />
      );
    }
  };
  const handleDeleteContract = async () => {
    const createToastMessage = (type) => {
      return type === "success" ? (
        <div>
          Contract for Bundle <b>{elementToBeDeleted.contractName}</b> has been
          successfully removed.
        </div>
      ) : (
        "Error removing contract"
      );
    };

    setDeleteInProgress(true);

    try {
      await axios.post(
        `${config.api.api_gateway_url}/admin/contracts/delete`,
        [
          {
            id: elementToBeDeleted?.contractId,
            type: elementToBeDeleted?.contractType,
            orgId: elementToBeDeleted?.organizationId,
          },
        ],
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        }
      );

      // Successful deletion
      setToastMessage(createToastMessage("success"));
      setToastType("Positive");
    } catch (error) {
      console.error(
        "Error deleting contracts:",
        error.response?.data || error.message
      );
      setToastMessage(createToastMessage("error"));
      setToastType("Negative");
    } finally {
      // Common state updates
      setDeleteDialogOpen(false);
      setDeleteInProgress(false);
      setIsLoading(true);
      setShowToast(true);
    }
  };

  // Memoize column definitions to avoid re-creation on every render
  const columns = useMemo(
    () => [
      {
        cellRendererFramework: EllipsisCellRenderer,
        field: "bundleName",
        headerName: "Bundle Name",
        minWidth: 350,
        sortable: true,
      },
      {
        cellRendererFramework: EllipsisCellRenderer,
        field: "numOfApis",
        headerName: "No of APIs",
        sortable: true,
        maxWidth: 220,
      },
      {
        cellRendererFramework: EllipsisCellRenderer,
        field: "createdBy",
        headerName: "Created by",
        minWidth: 300,
      },
      ...(!selectedFields
        ? [
            {
              cellRendererFramework: DateCellRenderer,
              field: "contractedOn",
              headerName: "Created On",
              minWidth: 300,
            },
          ]
        : []),
      {
        cellRendererFramework: EllipsisCellRenderer,
        field: "visibility",
        headerName: "Visibility",
        minWidth: 200,
      },
      {
        field: "action",
        headerName: "Action",
        minWidth: 20,
        cellRendererFramework: DeleteContractActionButtonRenderer,
      },
    ],
    []
  );

  useEffect(() => {
    let tableData;
    if (selectedFields) {
      tableData =
        selectedBundles?.map((bundle) => ({
          bundleName: bundle?.name,
          numOfApis: bundle?.apis?.length,
          createdBy: bundle?.createdBy,
          contractedOn: bundle?.created,
          visibility: bundle?.visibility,
          fullBundle: bundle,
        })) || [];
    } else {
      tableData =
        contractedBundles?.map((bundle) => ({
          bundleName: bundle?.contractName,
          numOfApis: bundle?.apis?.length,
          createdBy: bundle?.createdBy,
          contractedOn: bundle?.created,
          visibility: bundle?.visibility,
          fullBundle: bundle,
        })) || [];
    }

    setData(tableData);
    setContractedBundlesCount(contractedBundles.length);
  }, [contractedBundles, selectedBundles, selectedFields]);

  // Memoize header properties to avoid unnecessary re-renders
  const headerProps = useMemo(
    () => ({
      dataTestId: `contracted-bundles-table-header`,
      headerTitle: (
        <div>
          Contracted Bundles
          {contractedBundlesCount > 0 ? ` (${contractedBundlesCount})` : ""}
        </div>
      ),
      hideBorder: false,
      hideTableHeader: false,
      hideTableTitle: false,
      id: `contracted-bundles-table-header`,
      isAlwaysExpanded: false,
      isSearchable: false,
      isPrintable: false,
      isDownloadable: false,
      overflowButtonProps: undefined,
    }),
    [contractedBundlesCount]
  );

  const handleGridReady = (gridParams) => {
    gridParams.api.sizeColumnsToFit();
    gridParams.api.refreshCells({ force: true });
    gridParams.api.showNoRowsOverlay();
  };

  return (
    <TableContainer
      id="ag-grid-container"
      className={classNames("contracted-bundles_grid", "ag-theme-balham")}
    >
      <TableHeader {...headerProps} />

      <TableContent
        data-testid="table-content"
        className="contracted-bundles_grid-content"
        ref={tableContentRef}
      >
        <AgGridReact
          columnDefs={columns}
          domLayout={TABLE_LAYOUT_OPTIONS.AUTO_HEIGHT}
          rowData={data}
          rowHeight={50}
          style={{ width: "100%" }}
          ref={gridRef}
          onGridReady={handleGridReady}
          suppressRowClickSelection={true}
          frameworkComponents={{
            customNoRowsOverlay: CustomNoRowsOverlay,
          }}
          noRowsOverlayComponent="customNoRowsOverlay"
        />
      </TableContent>
      {
        <Dialog
          buttonsProps={[
            {
              id: "remove_bundle_contract_dialog-action-1",
              onClick: function noRefCheck() {
                setDeleteDialogOpen(false);
              },
              text: "Cancel",
              variant: BUTTON_VARIANTS.TEXT,
            },
            {
              id: "remove_bundle_contract_dialog-action-2",
              dataTestId: "shareConfirm",
              onClick: function noRefCheck() {
                handleDeleteContract();
              },
              text: deleteInProgress ? (
                <IndeterminateProgressCircle size="small" />
              ) : (
                "Delete"
              ),
              variant: deleteInProgress
                ? BUTTON_VARIANTS.TEXT
                : BUTTON_VARIANTS.PRIMARY,
            },
          ]}
          dataTestId="remove_bundle_contract_sbdialog"
          id="remove_bundle_contractsbdialog"
          onClose={() => setDeleteDialogOpen(false)}
          title={"Remove Bundle Contract"}
          isOpen={deleteDialogOpen}
          className="bundle_share_dialog_box"
        >
          This action cancels the contract for bundle (
          <b>{elementToBeDeleted?.contractName}</b>). Are you sure?
          <br />
          <br />
        </Dialog>
      }
    </TableContainer>
  );
}
